import axios from "axios";

export const instance = () => {
  return axios.create({
    baseURL: `${process.env.GATSBY_API_URL}`,
    responseType: "json",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ayana-client-type": "website",
    },
  });
};
