import axios from "axios";
import { instance } from "./Axios";
import { setUser, setAuthToken, getAuthToken, setAuthTokenState } from "./Auth";
import { headerConfig, headerConfigWithAuthorize } from "./Header";
import { loadState, saveState } from "state/utils/localStorage";
import { getLang } from "../state/reducers/langSession";
import { getGuestCompleteInfo } from "../apis/guest";

const isBrowser = typeof window !== "undefined";

export const guestInfo = async (token) => {
  try {
    if (!token) token = getAuthToken();

    if (!isBrowser) return false;

    // instance()
    const requestData = await axios({
      method: "GET",
      crossDomain: true,
      url: `${process.env.GATSBY_API_URL}/api/guest/info`,
      headers: {
        "ayana-lang": getLang(),
        Accept: "application/vnd.ayanamobile.v1+json",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: token ? `bearer ${token}` : undefined,
        "Accept-Language": getLang(),
      },
    }).catch(function (error) {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request.data;
      } else {
        console.log("Error", error.message);
      }
    });

    if (requestData.data.status_code == 200) {
      setAuthTokenState(token);
      setUser(requestData.data.data[0]);
      return true;
    }

    return false;
  } catch (error) {
    console.log(error);
  }
};

export const getRewardsProfile = async (token) => {
  try {
    if (!token) token = getAuthToken();

    const requestData = await axios({
      method: "GET",
      url: `${process.env.GATSBY_API_URL}/api/rewards/profile`,
      headers: {
        "ayana-lang": getLang(),
        Authorization: token ? `bearer ${token}` : undefined,
        "Accept-Language": getLang(),
      },
    }).catch(function (error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error", error.message);
      }
    });

    if (requestData.data.status_code == 200) {
      saveState("rewards_profile", JSON.stringify(requestData.data.data.point));
      return true;
    } else if (requestData.data.status_code == 400141) {
      saveState("show_profile_error_message", "0");
      saveState("rewards_profile_error_message", requestData.data.message);
      return null;
    } else return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getCompleteInfo = async (token) => {
  try {
    const { status, data: responseData } = await getGuestCompleteInfo(token);
    const { rewardsInfo } = responseData.data;
    if (status === 200) {
      setUser(responseData.data);
      saveState("rewards_profile", JSON.stringify(rewardsInfo?.points));
      setAuthTokenState(token);

      return true;
    } else {
      saveState("show_profile_error_message", "0");
      saveState("rewards_profile_error_message", status);
      return null;
    }
  } catch (error) {
    console.log("requestData error:", error);
    return null;
  }
};
