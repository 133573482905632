import { getGuestCompleteInfo } from "../../apis/guest";
import { setUser } from "../../services/Auth";
import { GUEST_INFO } from "../constants/actions";
import { fetchMemberAction } from "./memberActions";

export const fetchGuestInfoAction = (params) => {
  return async (dispatch) => {
    try {
      const response = await fetchMemberAction(
        GUEST_INFO,
        dispatch,
        getGuestCompleteInfo,
        params
      );
      setUser(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
};
