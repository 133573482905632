import { middlewareInstance } from "./middlewareInstance";

export const requestGuestDetails = async (auth0Token) => {
  const response = await middlewareInstance.get(`/api/v1/guests`, {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
    },
  });
  return response.data;
};

export const updateGuestDetails = async ({
  auth0Token,
  guestId,
  guestDetails,
}) => {
  const response = await middlewareInstance.put(
    `/api/v1/guests/${guestId}`,
    {
      guest: {
        ...guestDetails,
      },
    },
    {
      headers: {
        Authorization: `Bearer ${auth0Token}`,
      },
    }
  );
  return response.data;
};

export const getGuestCompleteInfo = async (auth0Token) => {
  const response = await middlewareInstance.get(
    `/api/v1/guests/get_complete_info`,
    {
      headers: {
        Authorization: `Bearer ${auth0Token}`,
      },
    }
  );

  return response;
};
