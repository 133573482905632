import { getLang } from "../state/reducers/langSession";
import { getAuthToken } from "../services/Auth";

export const headerConfig = () => {
  const config = {
    headers: {
      "ayana-lang": getLang(),
      "ayana-client-type": "website",
      "Accept-Language": getLang(),
    },
  };

  return config;
};

export const headerConfigWithAuthorize = (token) => {
  const config = {
    headers: {
      "ayana-lang": getLang(),
      "ayana-client-type": "website",
      Authorization: token ? `bearer ${token}` : undefined,
      Locale: getLang(),
      "Accept-Language": getLang(),
    },
  };

  return config;
};

export const headerConfigFormUpload = (token) => {
  const config = {
    headers: {
      "ayana-lang": getLang(),
      "ayana-client-type": "website",
      "Content-Type": "multipart/form-data",
      "Accept-Language": getLang(),
    },
  };

  return config;
};

export const headerConfigFormUploadWithAuthorize = (token) => {
  const config = {
    headers: {
      "ayana-lang": getLang(),
      locale: getLang(),
      "ayana-client-type": "website",
      "Content-Type": "multipart/form-data",
      Authorization: token ? `bearer ${token}` : undefined,
      "Accept-Language": getLang(),
    },
  };

  return config;
};
