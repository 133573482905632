import { FETCH_SUCCESS, UPDATE_MEMBER } from "../constants/actions";
import { setError, setInProgress } from "./commonActions";

export const setMember = (name, member = {}) => {
  return {
    type: `@${name}/${FETCH_SUCCESS}`,
    payload: {
      member,
    },
  };
};

export const updateMember = (name, partialMember = {}) => {
  return {
    type: `@${name}/${UPDATE_MEMBER}`,
    payload: {
      member: partialMember,
    },
  };
};

export const fetchMemberAction = async (
  name,
  dispatch,
  service,
  params = {}
) => {
  dispatch(setInProgress(name));
  try {
    const { data } = await service(params);
    dispatch(setMember(name, data));

    return data;
  } catch (err) {
    let message;

    if (err.response) {
      if (err.response.data) {
        message = err.response.data.message;
      }
      message = message || err.response.statusText;
    }
    const error = {
      message: message || err.message,
    };

    dispatch(setError(name, error));
  }
};
