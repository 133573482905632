import { FETCH, FETCH_FAILED, RESET } from "../constants/actions";

export const setInProgress = (name, type = FETCH) => {
  return {
    type: `@${name}/${type}`,
  };
};

export const setError = (name, error) => {
  return {
    type: `@${name}/${FETCH_FAILED}`,
    payload: { error },
  };
};
export const reset = (name) => {
  return {
    type: `@${name}/${RESET}`,
  };
};
